<template>
  <el-container direction="vertical">
    <Header></Header>
    <el-main>
      <!--广告-->
      <div class="ad">
        <!--广告容器-->
        <div class="ad_container">
          <!--分栏广告-->
          <BannerAds></BannerAds>
          <!--文字广告-->
          <TextAds class="mt22"></TextAds>
        </div>
      </div>
      <!--面包屑-->
      <div class="bread_crumbs">
        <div class="bread_content">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/">首页</el-breadcrumb-item>
            <el-breadcrumb-item to="/sportsman">球员类别</el-breadcrumb-item>
            <el-breadcrumb-item>{{ sportsman_info.cover_info.chinese_name }}</el-breadcrumb-item>
            <span style="float: right; font-size: 12px;">{{ sportsman_info.author_name }}</span>
          </el-breadcrumb>
        </div>
      </div>
      <!--内容-->
      <div class="content">
        <!--球员简介-->
        <div class="sportsman_synopsis">
          <p class="title">简介 Synopsis</p>
          <span>{{ sportsman_info.details.synopsis }}</span>
        </div>
        <!--球员详情-->
        <div class="sportsman_details">
          <!--照片-->
          <div class="photo">
            <img :src="sportsman_info.details.image" alt="球员图片">
          </div>
          <!--详情-->
          <div class="details">
            <div class="title">
              细节 Details
              <el-tag style="float: right; color: white" color="#d10c20">{{ sportsman_info.game_version }}</el-tag>
            </div>

            <!--名 First Name-->
            <p class="text">
              <span class="label">名 First Name</span>
              <span class="value">{{ sportsman_info.details.first_name }}</span>
            </p>
            <!--姓 Second Name-->
            <p class="text">
              <span class="label">姓 Second Name</span>
              <span class="value">{{ sportsman_info.details.second_name }}</span>
            </p>
            <!--常用名 Common Name-->
            <p class="text">
              <span class="label">常用名 Common Name</span>
              <span class="value">{{ sportsman_info.details.common_name }}</span>
            </p>
            <!--全名 Full Name-->
            <p class="text">
              <span class="label">全名 Full Name</span>
              <span class="value">{{ sportsman_info.details.full_name }}</span>
            </p>
            <!--种族 Ethnicity-->
            <p class="text">
              <span class="label">种族 Ethnicity</span>
              <span class="value">{{ sportsman_info.details.ethnicity }}</span>
            </p>
            <!--发色 Hair Colour-->
            <p class="text">
              <span class="label">发色 Hair Colour</span>
              <span class="value">{{ sportsman_info.details.hair_colour }}</span>
            </p>
            <!--头发长度 Hair Length-->
            <p class="text">
              <span class="label">头发长度 Hair Length</span>
              <span class="value">{{ sportsman_info.details.hair_length }}</span>
            </p>
            <!--肤色 Skin Colour-->
            <p class="text">
              <span class="label">肤色 Skin Colour</span>
              <span class="value">{{ sportsman_info.details.skin_colour }}</span>
            </p>
            <!--生日 Date Of Birth-->
            <p class="text">
              <span class="label">生日 Date Of Birth</span>
              <span class="value">{{ sportsman_info.details.date_of_birth }}</span>
            </p>
            <!--出生城市 City Of Birth-->
            <p class="text">
              <span class="label">出生城市 City Of Birth</span>
              <span class="value">{{ sportsman_info.details.city_of_birth }}</span>
            </p>
            <!--国家/地区 Nation-->
            <p class="text">
              <span class="label">国家/地区 Nation</span>
              <span class="value">{{ sportsman_info.details.nation }}</span>
            </p>
            <!--第二国籍/地区籍 Cecond Nations-->
            <p class="text">
              <span class="label">第二国籍/地区籍 Cecond Nations</span>
              <span class="value">{{ sportsman_info.details.cecond_nations }}</span>
            </p>
            <!--青年队时宣布效力国家/地区 Nation Declared For At Youth Level-->
            <p class="text">
              <span class="label">青年队时宣布效力国家/地区 Nation Declared For At Youth Level</span>
              <span class="value">{{ sportsman_info.details.nation_declared_for_at_youth_level }}</span>
            </p>
            <!--国家/地区队退役时间 International Retirement Date-->
            <p class="text">
              <span class="label">国家/地区队退役时间 International Retirement Date</span>
              <span class="value">{{ sportsman_info.details.international_retirement_date }}</span>
            </p>
          </div>
        </div>
        <!--球员属性-->
        <div class="sportsman_attribute">
          <!--购买按钮-->
<!--          <div class="buy_data" v-if="sportsman_info.integral > 0 && sportsman_info.is_buy === false">-->
          <div class="buy_data" v-if="sportsman_info.integral > 0 && !sportsman_info.is_buy">
            <div class="attribute_item" style="text-align: center">
              <p style="color: red">详细数据已经被影藏了哦, 如需查看请购买哦!</p>
              <el-button type="success" style="margin-top: 20px" @click="buy_dialog=true">购买隐藏数据(售价: {{ sportsman_info.integral }}积分)</el-button>
            </div>
          </div>
          <!--左边-->
          <div v-if="detail_show" class="left">
            <!--国际赛事资料 International Data-->
            <div class="attribute_item">
              <div class="title">国际赛事资料 International Data</div>
              <!--国家/地区队比赛上场次数 International Apps-->
              <p class="text">
                <span class="label">国家/地区队比赛上场次数 International Apps</span>
                <span class="value">{{ sportsman_info.international_data.international_apps }}</span>
              </p>
              <!--国家/地区队比赛进球数 International Goals-->
              <p class="text">
                <span class="label">国家/地区队比赛进球数 International Goals</span>
                <span class="value">{{ sportsman_info.international_data.international_goals }}</span>
              </p>
              <!--21岁以下国家/地区队比赛上场次数 U21International Apps-->
              <p class="text">
                <span class="label">21岁以下国家/地区队比赛上场次数 U21International Apps</span>
                <span class="value">{{ sportsman_info.international_data.u21_international_apps }}</span>
              </p>
              <!--21岁以下国家/地区队比赛进球数 U21 International Goals-->
              <p class="text">
                <span class="label">21岁以下国家/地区队比赛进球数 U21 International Goals</span>
                <span class="value">{{ sportsman_info.international_data.u21_international_goals }}</span>
              </p>
              <!--国际比赛处子秀日期 International Debut Date-->
              <p class="text">
                <span class="label">国际比赛处子秀日期 International Debut Date</span>
                <span class="value">{{ sportsman_info.international_data.international_debut_date }}</span>
              </p>
              <!--国家/地区队处子秀对手 International Debut Against-->
              <p class="text">
                <span class="label">国家/地区队处子秀对手 International Debut Against</span>
                <span class="value">{{ sportsman_info.international_data.international_debut_against }}</span>
              </p>
              <!--国家/地区队处子球日期 Date Of First International Goal -->
              <p class="text">
                <span class="label">国家/地区队处子球日期 Date Of First International Goal </span>
                <span class="value">{{ sportsman_info.international_data.date_of_first_international_goal }}</span>
              </p>
              <!--首粒国际比赛进球对手 First International Goal Againt-->
              <p class="text">
                <span class="label">首粒国际比赛进球对手 First International Goal Againt</span>
                <span class="value">{{ sportsman_info.international_data.first_international_goal_againt }}</span>
              </p>
              <!--其他国家/地区队上场次数 Other International Apps-->
              <p class="text">
                <span class="label">其他国家/地区队上场次数 Other International Apps</span>
                <span class="value">{{ sportsman_info.international_data.other_international_apps }}</span>
              </p>
              <!--其他国家/地区队比赛进球 Other International Goals-->
              <p class="text">
                <span class="label">其他国家/地区队比赛进球 Other International Goals</span>
                <span class="value">{{ sportsman_info.international_data.other_international_goals }}</span>
              </p>
              <!--其他青年队国家/地区队比赛上场次数 U21 Other International Apps-->
              <p class="text">
                <span class="label">其他青年队国家/地区队比赛上场次数 U21 Other International Apps</span>
                <span class="value">{{ sportsman_info.international_data.u21_other_international_apps }}</span>
              </p>
              <!--其他青年队国家/地区队比赛进球数 U21 Other International Goals-->
              <p class="text">
                <span class="label">其他青年队国家/地区队比赛进球数 U21 Other International Goals</span>
                <span class="value">{{ sportsman_info.international_data.u21_other_international_goals }}</span>
              </p>
            </div>
            <!--合同 Contract-->
            <div class="attribute_item">
              <div class="title">合同 Contract</div>
              <!--喜爱号码 Preferred Squad Number-->
              <p class="text">
                <span class="label">喜爱号码 Preferred Squad Number</span>
                <span class="value">{{ sportsman_info.contract.preferred_squad_number }}</span>
              </p>
            </div>
            <!--属性 Attributes-->
            <div class="attribute_item">
              <div class="title">属性 Attributes</div>
              <!--适应性 Adaptability-->
              <p class="text">
                <span class="label">适应性 Adaptability</span>
                <span class="value">{{ sportsman_info.attributes.adaptability }}</span>
              </p>
              <!--雄心 Ambition-->
              <p class="text">
                <span class="label">雄心 Ambition</span>
                <span class="value">{{ sportsman_info.attributes.ambition }}</span>
              </p>
              <!--争论 Controversy-->
              <p class="text">
                <span class="label">争论 Controversy</span>
                <span class="value">{{ sportsman_info.attributes.controversy }}</span>
              </p>
              <!--忠诚 Loyalty-->
              <p class="text">
                <span class="label">忠诚 Loyalty</span>
                <span class="value">{{ sportsman_info.attributes.loyalty }}</span>
              </p>
              <!--抗压能力 Pressure -->
              <p class="text">
                <span class="label">抗压能力 Pressure </span>
                <span class="value">{{ sportsman_info.attributes.pressure }}</span>
              </p>
              <!--职业 Professionalism-->
              <p class="text">
                <span class="label">职业 Professionalism</span>
                <span class="value">{{ sportsman_info.attributes.professionalism }}</span>
              </p>
              <!--体育道德 Sportsmanship-->
              <p class="text">
                <span class="label">体育道德 Sportsmanship</span>
                <span class="value">{{ sportsman_info.attributes.sportsmanship }}</span>
              </p>
              <!--情绪控制 Temperament-->
              <p class="text">
                <span class="label">情绪控制 Temperament</span>
                <span class="value">{{ sportsman_info.attributes.temperament }}</span>
              </p>
              <!--语言 Language-->
              <p class="text">
                <span class="label">语言 Language</span>
                <span class="value">{{ sportsman_info.attributes.language }}</span>
              </p>
            </div>
            <!--综合 General-->
            <div class="attribute_item">
              <div class="title">综合 General</div>
              <!--能力 Ability-->
              <p class="text">
                <span class="label">能力 Ability</span>
                <span class="value">{{ sportsman_info.general.ability }}</span>
              </p>
              <!--身高 Height-->
              <p class="text">
                <span class="label">身高 Height</span>
                <span class="value">{{ sportsman_info.general.height }}</span>
              </p>
              <!--体重 Weight-->
              <p class="text">
                <span class="label">体重 Weight</span>
                <span class="value">{{ sportsman_info.general.weight }}</span>
              </p>
              <!--左脚 Left Foot-->
              <p class="text">
                <span class="label">左脚 Left Foot</span>
                <span class="value">{{ sportsman_info.general.left_foot }}</span>
              </p>
              <!--右脚 Right Foot-->
              <p class="text">
                <span class="label">右脚 Right Foot</span>
                <span class="value">{{ sportsman_info.general.right_foot }}</span>
              </p>
              <!--国内培养 Trained In Nation-->
              <p class="text">
                <span class="label">国内培养 Trained In Nation</span>
                <span class="value">{{ sportsman_info.general.trained_in_nation }}</span>
              </p>
              <!--俱乐部培养 Trained At Club-->
              <p class="text">
                <span class="label">俱乐部培养 Trained At Club</span>
                <span class="value">{{ sportsman_info.general.trained_at_club }}</span>
              </p>
            </div>
            <!--位置 Positions-->
            <div class="attribute_item">
              <div class="title">位置 Positions</div>
              <!--门将 Goalkeeper-->
              <p class="text">
                <span class="label">门将 Goalkeeper</span>
                <span class="value">{{ sportsman_info.positions.goalkeeper }}</span>
              </p>
              <!--清道夫 Sweeper-->
              <p class="text">
                <span class="label">清道夫 Sweeper</span>
                <span class="value">{{ sportsman_info.positions.sweeper }}</span>
              </p>
              <!--左后卫 Defender Left-->
              <p class="text">
                <span class="label">左后卫 Defender Left</span>
                <span class="value">{{ sportsman_info.positions.defender_left }}</span>
              </p>
              <!--中后卫 Defender Central-->
              <p class="text">
                <span class="label">中后卫 Defender Central</span>
                <span class="value">{{ sportsman_info.positions.defender_central }}</span>
              </p>
              <!--右后卫 Defender Right-->
              <p class="text">
                <span class="label">右后卫 Defender Right</span>
                <span class="value">{{ sportsman_info.positions.defender_right }}</span>
              </p>
              <!--防守型中场 Defensive Midfielder-->
              <p class="text">
                <span class="label">防守型中场 Defensive Midfielder</span>
                <span class="value">{{ sportsman_info.positions.defensive_midfielder }}</span>
              </p>
              <!--左边卫 Wing Back Left-->
              <p class="text">
                <span class="label">左边卫 Wing Back Left</span>
                <span class="value">{{ sportsman_info.positions.wing_back_left }}</span>
              </p>
              <!--右边卫 Wing Back Right-->
              <p class="text">
                <span class="label">右边卫 Wing Back Right</span>
                <span class="value">{{ sportsman_info.positions.wing_back_right }}</span>
              </p>
              <!--左前卫 Midfielder Left-->
              <p class="text">
                <span class="label">左前卫 Midfielder Left</span>
                <span class="value">{{ sportsman_info.positions.midfielder_left }}</span>
              </p>
              <!--中前卫 Midfielder Central-->
              <p class="text">
                <span class="label">中前卫 Midfielder Central</span>
                <span class="value">{{ sportsman_info.positions.midfielder_central }}</span>
              </p>
              <!--右前卫 Midfielder Right-->
              <p class="text">
                <span class="label">右前卫 Midfielder Right</span>
                <span class="value">{{ sportsman_info.positions.midfielder_right }}</span>
              </p>
              <!--左路攻击前卫 Attacking Midfielder Left-->
              <p class="text">
                <span class="label">左路攻击前卫 Attacking Midfielder Left</span>
                <span class="value">{{ sportsman_info.positions.attacking_midfielder_left }}</span>
              </p>
              <!--中路攻击前卫 Attacking Midfielder Central-->
              <p class="text">
                <span class="label">中路攻击前卫 Attacking Midfielder Central</span>
                <span class="value">{{ sportsman_info.positions.attacking_midfielder_central }}</span>
              </p>
              <!--右路攻击前卫 Attacking Midfielder Right-->
              <p class="text">
                <span class="label">右路攻击前卫 Attacking Midfielder Right</span>
                <span class="value">{{ sportsman_info.positions.attacking_midfielder_right }}</span>
              </p>
              <!--前锋 Attacker-->
              <p class="text">
                <span class="label">前锋 Attacker</span>
                <span class="value">{{ sportsman_info.positions.attacker }}</span>
              </p>
              <!--主打位置 Preferred Central Position-->
              <p class="text">
                <span class="label">主打位置 Preferred Central Position</span>
                <span class="value">{{ sportsman_info.positions.preferred_central_position }}</span>
              </p>
            </div>
            <!--精神属性 Mental Attributes-->
            <div class="attribute_item">
              <div class="title">精神属性 Mental Attributes</div>
              <!--侵略性 Aggression-->
              <p class="text">
                <span class="label">侵略性 Aggression</span>
                <span class="value">{{ sportsman_info.mental_attributes.aggression }}</span>
              </p>
              <!--预判 Anticipation-->
              <p class="text">
                <span class="label">预判 Anticipation</span>
                <span class="value">{{ sportsman_info.mental_attributes.anticipation }}</span>
              </p>
              <!--勇敢 Bravery-->
              <p class="text">
                <span class="label">勇敢 Bravery</span>
                <span class="value">{{ sportsman_info.mental_attributes.bravery }}</span>
              </p>
              <!--镇定 Composure-->
              <p class="text">
                <span class="label">镇定 Composure</span>
                <span class="value">{{ sportsman_info.mental_attributes.composure }}</span>
              </p>
              <!--集中 Concentration-->
              <p class="text">
                <span class="label">集中 Concentration</span>
                <span class="value">{{ sportsman_info.mental_attributes.concentration }}</span>
              </p>
              <!--稳定 Consistency-->
              <p class="text">
                <span class="label">稳定 Consistency</span>
                <span class="value">{{ sportsman_info.mental_attributes.consistency }}</span>
              </p>
              <!--决断 Decisions-->
              <p class="text">
                <span class="label">决断 Decisions</span>
                <span class="value">{{ sportsman_info.mental_attributes.decisions }}</span>
              </p>
              <!--意志力 Determination-->
              <p class="text">
                <span class="label">意志力 Determination</span>
                <span class="value">{{ sportsman_info.mental_attributes.determination }}</span>
              </p>
              <!--坑脏动作 Dirtyness-->
              <p class="text">
                <span class="label">坑脏动作 Dirtyness</span>
                <span class="value">{{ sportsman_info.mental_attributes.dirtyness }}</span>
              </p>
              <!--想象力 Flair-->
              <p class="text">
                <span class="label">想象力 Flair</span>
                <span class="value">{{ sportsman_info.mental_attributes.flair }}</span>
              </p>
              <!--大赛发挥 Important Matches-->
              <p class="text">
                <span class="label">大赛发挥 Important Matches</span>
                <span class="value">{{ sportsman_info.mental_attributes.important_matches }}</span>
              </p>
              <!--影响力 Influence-->
              <p class="text">
                <span class="label">影响力 Influence</span>
                <span class="value">{{ sportsman_info.mental_attributes.influence }}</span>
              </p>
              <!--无球跑动 Off The Ball-->
              <p class="text">
                <span class="label">无球跑动 Off The Ball</span>
                <span class="value">{{ sportsman_info.mental_attributes.off_the_ball }}</span>
              </p>
              <!--防守站位 Positioning-->
              <p class="text">
                <span class="label">防守站位 Positioning</span>
                <span class="value">{{ sportsman_info.mental_attributes.positioning }}</span>
              </p>
              <!--团队合作 Team Work-->
              <p class="text">
                <span class="label">团队合作 Team Work</span>
                <span class="value">{{ sportsman_info.mental_attributes.team_work }}</span>
              </p>
              <!--视野 Cretivity-->
              <p class="text">
                <span class="label">视野 Cretivity</span>
                <span class="value">{{ sportsman_info.mental_attributes.cretivity }}</span>
              </p>
              <!--工作投入 Work Rate-->
              <p class="text">
                <span class="label">工作投入 Work Rate</span>
                <span class="value">{{ sportsman_info.mental_attributes.work_rate }}</span>
              </p>
            </div>
            <!--身体属性 Physical Attributes-->
            <div class="attribute_item">
              <div class="title">身体属性 Physical Attributes</div>
              <!--爆发力 Acceleration-->
              <p class="text">
                <span class="label">爆发力 Acceleration</span>
                <span class="value">{{ sportsman_info.physical_attributes.acceleration }}</span>
              </p>
              <!--灵活 Agility-->
              <p class="text">
                <span class="label">灵活 Agility</span>
                <span class="value">{{ sportsman_info.physical_attributes.agility }}</span>
              </p>
              <!--平衡 Balance-->
              <p class="text">
                <span class="label">平衡 Balance</span>
                <span class="value">{{ sportsman_info.physical_attributes.balance }}</span>
              </p>
              <!--受伤倾向 Injury Proneness-->
              <p class="text">
                <span class="label">受伤倾向 Injury Proneness</span>
                <span class="value">{{ sportsman_info.physical_attributes.injury_proneness }}</span>
              </p>
              <!--弹跳 Jumping-->
              <p class="text">
                <span class="label">弹跳 Jumping</span>
                <span class="value">{{ sportsman_info.physical_attributes.jumping }}</span>
              </p>
              <!--体质 Natural Fitness-->
              <p class="text">
                <span class="label">体质 Natural Fitness</span>
                <span class="value">{{ sportsman_info.physical_attributes.natural_fitness }}</span>
              </p>
              <!--速度 Pace-->
              <p class="text">
                <span class="label">速度 Pace</span>
                <span class="value">{{ sportsman_info.physical_attributes.pace }}</span>
              </p>
              <!--耐力 Stamina-->
              <p class="text">
                <span class="label">耐力 Stamina</span>
                <span class="value">{{ sportsman_info.physical_attributes.stamina }}</span>
              </p>
              <!--强壮 Strength-->
              <p class="text">
                <span class="label">强壮 Strength</span>
                <span class="value">{{ sportsman_info.physical_attributes.strength }}</span>
              </p>
            </div>
            <!--技术属性 Technical Attributes-->
            <div class="attribute_item">
              <div class="title">技术属性 Technical Attributes</div>
              <!--角球 Corners-->
              <p class="text">
                <span class="label">角球 Corners</span>
                <span class="value">{{ sportsman_info.technical_attributes.corners }}</span>
              </p>
              <!--传中 Crossing-->
              <p class="text">
                <span class="label">传中 Crossing</span>
                <span class="value">{{ sportsman_info.technical_attributes.crossing }}</span>
              </p>
              <!--盘带 Dribbling-->
              <p class="text">
                <span class="label">盘带 Dribbling</span>
                <span class="value">{{ sportsman_info.technical_attributes.dribbling }}</span>
              </p>
              <!--射门 Finishing-->
              <p class="text">
                <span class="label">射门 Finishing</span>
                <span class="value">{{ sportsman_info.technical_attributes.finishing }}</span>
              </p>
              <!--接球 First Touch-->
              <p class="text">
                <span class="label">接球 First Touch</span>
                <span class="value">{{ sportsman_info.technical_attributes.first_touch }}</span>
              </p>
              <!--任意球 Free Kicks-->
              <p class="text">
                <span class="label">任意球 Free Kicks</span>
                <span class="value">{{ sportsman_info.technical_attributes.free_kicks }}</span>
              </p>
              <!--头球 Headings-->
              <p class="text">
                <span class="label">头球 Headings</span>
                <span class="value">{{ sportsman_info.technical_attributes.headings }}</span>
              </p>
              <!--远射 Long Shots-->
              <p class="text">
                <span class="label">远射 Long Shots</span>
                <span class="value">{{ sportsman_info.technical_attributes.long_shots }}</span>
              </p>
              <!--界外球 Long Throws-->
              <p class="text">
                <span class="label">界外球 Long Throws</span>
                <span class="value">{{ sportsman_info.technical_attributes.long_throws }}</span>
              </p>
              <!--盯人 Marking-->
              <p class="text">
                <span class="label">盯人 Marking</span>
                <span class="value">{{ sportsman_info.technical_attributes.marking }}</span>
              </p>
              <!--传球 Passing-->
              <p class="text">
                <span class="label">传球 Passing</span>
                <span class="value">{{ sportsman_info.technical_attributes.passing }}</span>
              </p>
              <!--罚点球 Penalty Taking-->
              <p class="text">
                <span class="label">罚点球 Penalty Taking</span>
                <span class="value">{{ sportsman_info.technical_attributes.penalty_taking }}</span>
              </p>
              <!--抢断 Tackling-->
              <p class="text">
                <span class="label">抢断 Tackling</span>
                <span class="value">{{ sportsman_info.technical_attributes.tackling }}</span>
              </p>
              <!--技术 Technique-->
              <p class="text">
                <span class="label">技术 Technique</span>
                <span class="value">{{ sportsman_info.technical_attributes.technique }}</span>
              </p>
              <!--多面性 Versatility-->
              <p class="text">
                <span class="label">多面性 Versatility</span>
                <span class="value">{{ sportsman_info.technical_attributes.versatility }}</span>
              </p>
            </div>
            <!--门将属性 Goalkeeping Attributes-->
            <div class="attribute_item">
              <div class="title">门将属性 Goalkeeping Attributes</div>
              <!--制空能力 Aerial Ability-->
              <p class="text">
                <span class="label">制空能力 Aerial Ability</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.aerial_ability }}</span>
              </p>
              <!--拦截传中 Command Of Area-->
              <p class="text">
                <span class="label">拦截传中 Command Of Area</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.command_of_area }}</span>
              </p>
              <!--指挥防守 Communication-->
              <p class="text">
                <span class="label">指挥防守 Communication</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.communication }}</span>
              </p>
              <!--神经指数 Eccentricity-->
              <p class="text">
                <span class="label">神经指数 Eccentricity</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.eccentricity }}</span>
              </p>
              <!--手控球 Handing-->
              <p class="text">
                <span class="label">手控球 Handing</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.handing }}</span>
              </p>
              <!--大脚开球 Kicking-->
              <p class="text">
                <span class="label">大脚开球 Kicking</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.kicking }}</span>
              </p>
              <!--一对一 One On Ones-->
              <p class="text">
                <span class="label">一对一 One On Ones</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.one_on_ones }}</span>
              </p>
              <!--反应 Reflexes-->
              <p class="text">
                <span class="label">反应 Reflexes</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.reflexes }}</span>
              </p>
              <!--出击 Rushing Out-->
              <p class="text">
                <span class="label">出击 Rushing Out</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.rushing_out }}</span>
              </p>
              <!--击球倾向 Tendency To Punch-->
              <p class="text">
                <span class="label">击球倾向 Tendency To Punch</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.tendency_to_punch }}</span>
              </p>
              <!--手抛球能力 Throwing-->
              <p class="text">
                <span class="label">手抛球能力 Throwing</span>
                <span class="value">{{ sportsman_info.goalkeeping_attributes.throwing }}</span>
              </p>
            </div>
            <!--习惯表现 Perferred Moves-->
            <div class="attribute_item">
              <div class="title">习惯表现 Perferred Moves</div>
              <p class="text" v-for="(item, index) in sportsman_info.perferred_moves" :key="index">
                <span class="label">{{ item.text }}</span>
              </p>
            </div>
          </div>
          <!--右边-->
          <div v-if="detail_show" class="right">
            <!--喜欢的人  Favourite People-->
            <div class="attribute_item">
              <div class="title">喜欢的人  Favourite People</div>
              <!--表格-->
              <table>
                <thead>
                  <tr>
                    <td>人员</td>
                    <td>级别</td>
                    <td>原因</td>
                    <td>持续</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in sportsman_info.favourite_people" :key="index" >
                    <td>{{ item.personnel }}</td>
                    <td>{{ item.level }}</td>
                    <td>{{ item.reason }}</td>
                    <td>{{ item.continued }}</td>
                  </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--厌恶的人  Disliked People-->
            <div class="attribute_item">
              <div class="title">厌恶的人  Disliked People</div>
              <!--表格-->
              <table>
                <thead>
                <tr>
                  <td>人员</td>
                  <td>级别</td>
                  <td>持续</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in sportsman_info.disliked_people" :key="index">
                  <td>{{ item.personnel }}</td>
                  <td>{{ item.level }}</td>
                  <td>{{ item.continued }}</td>
                </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--钟爱的俱乐部  Favourite Clubs-->
            <div class="attribute_item">
              <div class="title">钟爱的俱乐部  Favourite Clubs</div>
              <!--表格-->
              <table>
                <thead>
                  <tr>
                    <td>俱乐部</td>
                    <td>原因</td>
                    <td>级别</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in sportsman_info.favourite_clubs" :key="index">
                  <td>{{ item.club }}</td>
                  <td>{{ item.reason }}</td>
                  <td>{{ item.level }}</td>
                </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--讨厌的俱乐部  Disliked Clubs-->
            <div class="attribute_item">
              <div class="title">讨厌的俱乐部  Disliked Clubs</div>
              <!--表格-->
              <table>
                <thead>
                  <tr>
                    <td>俱乐部</td>
                    <td>级别</td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in sportsman_info.disliked_clubs" :key="index">
                  <td>{{ item.club }}</td>
                  <td>{{ item.level }}</td>
                </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--国家队生涯 National Team Career-->
            <div class="attribute_item">
              <div class="title">国家队生涯 National Team Career</div>
              <!--表格-->
              <table>
                <thead>
                  <tr>
                    <td>比赛日期</td>
                    <td>比赛对手</td>
                    <td>进球</td>
                    <td>助攻</td>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in sportsman_info.national_team_career" :key="index">
                  <td>{{ item.match_date }}</td>
                  <td>{{ item.opponent }}</td>
                  <td>{{ item.goal }}</td>
                  <td>{{ item.assist }}</td>
                </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--转会记录 Transfer History-->
            <div class="attribute_item">
              <div class="title">转会记录 Transfer History</div>
              <!--表格-->
              <table>
                <thead>
                  <tr>
                    <td>转会日期</td>
                    <td>转入球队</td>
                    <td>转会费</td>
                  </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in sportsman_info.transfer_history" :key="index">
                  <td>{{ item.transfer_date }}</td>
                  <td>{{ item.transfer_to_the_team }}</td>
                  <td>{{ item.transfer_fee }}</td>
                </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--球员历史 Playing History-->
            <div class="attribute_item">
              <div class="title">球员历史 Playing History</div>
              <!--表格-->
              <table>
                <thead>
                <tr>
                  <td>年</td>
                  <td>俱乐部</td>
                  <td>属性</td>
                  <td>登场</td>
                  <td>进球</td>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in sportsman_info.playing_history" :key="index">
                  <td>{{ item.year }}</td>
                  <td>{{ item.club }}</td>
                  <td>{{ item.attributes }}</td>
                  <td>{{ item.debut }}</td>
                  <td>{{ item.goal }}</td>
                </tr>
                </tbody>
              </table>
              <!--分页-->
              <div class="pages" >
                <el-pagination small layout="prev, pager, next" :total="10"/>
              </div>
            </div>
            <!--二维码-->
            <div class="attribute_item qr_code">
              <div class="qr_code_item">
                <img src="https://picsum.photos/200" alt="QQ群二维码">
                <p>
                  QQ扫描二维码
                  <br>
                  加入QQ群
                </p>
              </div>
              <div class="qr_code_item">
                <img src="https://picsum.photos/200" alt="微信群二维码">
                <p>
                  微信扫描二维码
                  <br>
                  加入微信群
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-main>
    <Footer></Footer>
  </el-container>
  <!--购买弹窗-->
  <el-dialog v-model="buy_dialog" title="购买确认" width="400px">
    <span>您确定要购买这个球员的详细信息吗?</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="buy_dialog = false">取消</el-button>
        <el-button type="primary" @click="buy_sportsman_detail(sportsman_info._id)">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import BannerAds from "../../components/BannerAds";
import TextAds from "../../components/TextAds";
import {get_sportsman, post_sportsman_buy} from "../../api/api";
import API_CONFIG from "../../../vue.config";
import {success_hint, error_hint} from "../../tool/tools";

 export default {
  name: "SportsmanDetail",
  created() {
    if(this.$route.query.Id !== null && this.$route.query.Id !== ""){
      this.get_sportsman_info(this.$route.query.Id);
    }
  },
  data(){
    return {
      sportsman_info: {
        _id: "",
        // 封面信息
        cover_info: {
          // 图片
          image: "",
          // 名字
          name: "",
          // 出生日期
          date_of_birth: "",
          // 场上位置
          field_position: "",
          // 巅峰能力
          peak_ability: 0,
          // 代表国家
          represent_the_country: "",
          is_buy: false,
          is_author: false
        },
        // 细节
        details: {
          // 图片
          image: "",
          // 名
          first_name: "",
          // 姓
          second_name: "",
          // 常用名
          common_name: "",
          // 全名
          full_name: "",
          // 种族
          ethnicity: "",
          // 发
          hair_colour: "",
          // 头发长度
          hair_length: "",
          // 肤色
          skin_colour: "",
          // 生日
          date_of_birth: "",
          // 出生城市
          city_of_birth: "",
          // 国家/地区
          nation: "",
          // 第二国籍/地区籍
          cecond_nations: "",
          // 青年队时宣布效力国家/地区
          nation_declared_for_at_youth_level: "",
          // 国家/地区队退役时间
          international_retirement_date: ""
        },
        // 国际赛事资料
        international_data: {
          // 国家/地区队比赛上场次数
          international_apps: 0,
          // 国家/地区队比赛进球数
          international_goals: 0,
          // 21岁以下国家/地区队比赛上场次数
          u21_international_apps: 0,
          // 21岁以下国家/地区队比赛进球数
          u21_international_goals: 0,
          // 国际比赛处子秀日期
          international_debut_date: "",
          // 国家/地区队处子秀对手
          international_debut_against: 0,
          // 国家/地区队处子球日期
          date_of_first_international_goal: "",
          // 首粒国际比赛进球对手
          first_international_goal_againt: 0,
          // 其他国家/地区队上场次数
          other_international_apps: 0,
          // 其他国家/地区队比赛进球
          other_international_goals: 0,
          // 其他青年队国家/地区队比赛上场次数
          u21_other_international_apps: 0,
          // 其他青年队国家/地区队比赛进球数
          u21_other_international_goals: 0
        },
        // 合同 Contract
        contract: {
          preferred_squad_number: 0
        },
        // 属性
        attributes: {
          // 适应性
          adaptability: 0,
          // 雄心
          ambition: 0,
          // 争论
          controversy: 0,
          // 忠诚
          loyalty: 0,
          // 抗压能力
          pressure: 0,
          // 职业
          professionalism: 0,
          // 体育道德
          sportsmanship: 0,
          // 情绪控制
          temperament: 0,
          // 语言
          language: ""
        },
        // 综合
        general: {
          // 能力
          ability: 0,
          // 身高
          height: 0,
          // 体重
          weight: 0,
          // 左脚
          left_foot: 0,
          // 右脚
          right_foot: 0,
          // 国内培养
          trained_in_nation: "",
          // 俱乐部培养
          trained_at_club: ""
        },
        // 位置
        positions: {
          // 门将
          goalkeeper: 0,
          // 清道夫
          sweeper: 0,
          //左后卫
          defender_left: 0,
          // 中后卫
          defender_central: 0,
          // 右后卫
          defender_right: 0,
          // 防守型中场
          defensive_midfielder: 0,
          // 左边卫
          wing_back_left: 0,
          // 右边卫
          wing_back_right: 0,
          // 左前卫
          midfielder_left: 0,
          // 中前卫
          midfielder_central: 0,
          // 右前卫
          midfielder_right: 0,
          // 左路攻击前卫
          attacking_midfielder_left: 0,
          // 中路攻击前卫
          attacking_midfielder_central: 0,
          // 右路攻击前卫
          attacking_midfielder_right: 0,
          // 前锋
          attacker: 0,
          // 主打位置
          preferred_central_position: ""
        },
        // 精神属性
        mental_attributes: {
          // 侵略性
          aggression: 0,
          // 预判
          anticipation: 0,
          // 勇敢
          bravery: 0,
          // 镇定
          composure: 0,
          // 集中
          concentration: 0,
          // 稳定
          consistency: 0,
          // 决断
          decisions: 0,
          // 意志力
          determination: 0,
          // 坑脏动作
          dirtyness: 0,
          // 想象力
          flair: 0,
          // 大赛发挥
          important_matches: 0,
          // 影响力
          influence: 0,
          // 无球跑动
          off_the_ball: 0,
          // 防守站位
          positioning: 0,
          // 团队合作
          team_work: 0,
          // 视野
          cretivity: 0,
          // 工作投入
          work_rate: 0
        },
        // 身体属性
        physical_attributes: {
          // 爆发力
          acceleration: 0,
          // 灵活
          agility: 0,
          // 平衡
          balance: 0,
          // 受伤倾向
          injury_proneness: 0,
          // 弹跳
          jumping: 0,
          // 体质
          natural_fitness: 0,
          // 速度
          pace: 0,
          // 耐力
          stamina: 0,
          // 强壮
          strength: 0
        },
        // 技术属性
        technical_attributes: {
          // 爆发力
          acceleration: 0,
          // 角球
          corners: 0,
          // 传中
          crossing: 0,
          // 盘带
          dribbling: 0,
          // 射门
          finishing: 0,
          // 接球
          first_touch: 0,
          // 任意球
          free_kicks: 0,
          // 头球
          headings: 0,
          // 远射
          long_shots: 0,
          // 界外球
          long_throws: 0,
          // 盯人
          marking: 0,
          // 传球
          passing: 0,
          // 罚点球
          penalty_taking: 0,
          // 技术
          technique: 0,
          // 多面性
          versatility: 0
        },
        // 门将属性
        goalkeeping_attributes: {
          // 制空能力
          aerial_ability: 0,
          // 拦截传中
          command_of_area: 0,
          // 指挥防守
          communication: 0,
          // 神经指数
          eccentricity: 0,
          // 手控球
          handing: 0,
          // 大脚开球
          kicking: 0,
          // 一对一
          one_on_ones: 0,
          // 反应
          reflexes: 0,
          // 出击
          rushing_out: 0,
          // 击球倾向
          tendency_to_punch: 0,
          // 手抛球能力
          throwing: 0
        },
        // 习惯表现数据
        perferred_moves_data: [],
        // 喜欢的人数据
        favourite_people_data:[],
        // 讨厌的人数据
        disliked_people_data: [],
        // 钟爱的俱乐部数据
        favourite_clubs_data: [],
        // 讨厌的俱乐部数据
        disliked_clubs_data: [],
        // 国家队生涯数据
        national_team_career_data: [],
        // 转会记录数据
        transfer_history_data: [],
        // 球员历史数据
        playing_history_data: [],
        // 游戏版本
        game_version: "",
        // 积分
        integral: 0,
        is_buy: false
      },
      // 购买弹窗
      buy_dialog: false,
      // 详情显示
      detail_show: false
    }
  },
  methods: {
    get_sportsman_info(id){
      get_sportsman({
        Id: id
      }).then(response => {
        if(response.Code === 1){
          this.sportsman_info = response.Data;
          // 设置图片
          this.sportsman_info.details.image = API_CONFIG.devServer.proxy["/api"].target + "/" + this.sportsman_info.details.image;
          console.log(response.Data);
          // 判断是否显示
          if(this.sportsman_info.is_author){
            this.detail_show = true;
          }else if(this.sportsman_info.integral === 0){
            this.detail_show = true;
          }else if(this.sportsman_info.is_buy){
            this.detail_show = true;
          }
        }
      })
    },
    // 购买球员详情
    buy_sportsman_detail(id){
      post_sportsman_buy({
        Id: id
      }).then(res => {
        if(res.Code === 1){
          success_hint("购买成功");
        }else {
          error_hint(res.Message);
        }
      });
      this.buy_dialog = false;
    }
  },
  components: {
    Header,
    Footer,
    BannerAds,
    TextAds
  }
}
</script>

<style scoped>
@import "../../static/universal/index.css";

.bread_crumbs{
  height: 36px;
  background: #2e2f31;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content{
  width: 1050px;
  height: 36px;
  line-height: 36px;
  margin: 0 auto;
  font-size: 18px;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
  color: #FFFFFF;
}

.bread_crumbs > .bread_content > .el-breadcrumb{
  line-height: 36px;
  font-size: 16px;
}

.bread_crumbs > .bread_content > .el-breadcrumb >>> .el-breadcrumb__inner{
  color: #FFFFFF;
}

.content > .sportsman_synopsis{
  background: #FFFFFF;
  margin-top: 10px;
  padding: 25px;
  align-items: flex-start;
}

.content > .sportsman_synopsis > span{
  width: 100%;
  display: inline-block;
  word-break: break-all;
  white-space: normal;
  font-size: 12px;
  color: #666666;
}

.content > .sportsman_details{
  background: #FFFFFF;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 25px;
  display: flex;
  align-items: flex-start;
}

.content > .sportsman_details > .photo{
  width: 300px;
  height: 400px;
  margin-right: 10px;
}

.content > .sportsman_details > .photo > img{
  width: 100%;
  height: 100%;
}

.content > .sportsman_details > .details{
  flex: 1;
  color: #666666;
}

.title {
  font-weight: bold;
  border-left: 2px solid #34b261;
  color: #34b261;
  padding-left: 9px;
  margin-bottom: 15px;
  font-size: 13px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px #f4f4f6 dashed;
  padding: 5px 0;
  font-size: 12px;
}

.content > .sportsman_attribute {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.content > .sportsman_attribute > .left{
  flex: 1;
}

.content > .sportsman_attribute > .buy_data{
  flex: 1;
}

.content > .sportsman_attribute > .right{
  width: 348px;
  margin-left: 10px;
}


.attribute_item {
  padding: 25px;
  background: #FFFFFF;
  margin-bottom: 10px;
  color: #666666;
}

.attribute_item > table{
  width: 100%;
  color: #666666;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
}

.attribute_item > table > thead, .attribute_item > table > thead > tr{
  border-bottom: 1px #f4f4f6 dashed;
}

.attribute_item > table > thead > tr > td{
  padding: 7px 0;
  text-align: left;
  font-weight: bold;
}

.attribute_item > table > tbody > tr > td{
  padding: 5px 0;
}

.attribute_item > .pages {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.el-pagination{
  --el-pagination-hover-color: #34b261;
}

.buy{
  margin: 20px 20px;
}

</style>